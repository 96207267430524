import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import Swal from "sweetalert2";
import { API_URL } from "../../../const";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { AiOutlineDelete } from "react-icons/ai";

const AddLedger = () => {
  const [clientList, setClientList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [isError, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cashList, setCashList] = useState([]);
  const [bankList, setBankList] = useState([]);

  const getClient = () => {
    axios.post(API_URL.CLIENT_LIST, {}).then((res) => {
      let ct = res.data.data.map((raw) => {
        return {
          label: raw.firm_name,
          value: raw.id,
        };
      });
      setClientList(ct);
    });
  };

  const getGroupClient = () => {
    axios.post(API_URL.GROUPS_LIST, {}).then((res) => {
      let ct = res.data.data.map((raw) => {
        return {
          label: raw.name,
          value: raw.id,
        };
      });
      setGroupList(ct);
    });
  };

  const [formData, setFormData] = useState({
    date: "",
    amount: "",
    remark: "",
    received_by: "",
    payment_mode: "",
    client_id: "",
    ledger_for: "",
    group_id: "",
    payment_date: "",
  });

  const Invoiceoptions = [
    { value: "Client", label: "Client" },
    { value: "Group", label: "Group" },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const getLedgerData = () => {
    axios.post(API_URL.LEDGER_RECEIPT_LIST, {}).then(
      (res) => {
        if (res.data.status === 1) {
          setLedgers(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };

  const saveLedger = () => {
    const isError = {};
    if (!formData.date) {
      isError.date = "Payment date is required";
    }
    if (!formData.ledger_for) {
      isError.ledger_for = "Payment type is required";
    }
    if (!formData.client_id ) {
      isError.client_id = "Amount is required";
    }
    
    // if (!formData.group_id) {
    //   isError.group_id = "Remark is required";
    // }
    if (!formData.payment_mode ) {
      isError.payment_mode = "Description is required";
    }
    if (!formData.amount ) {
      isError.amount = "Description is required";
    }
    if (!formData.remark) {
      isError.remark = "Description is required";
    }
    if (!formData.received_by) {
      isError.received_by = "Description is required";
    }
    if (Object.keys(isError).length > 0) {
      setError(isError);
      return;
    }
    setError(false);
    let body = { ...formData, amount_for: "Dr" };
    body.date = formData.date
      ? moment(formData.date).format("YYYY-MM-DD")
      : null;
    const pUrl = API_URL.LEDGER_SAVE;
    
    axios.post(pUrl, body).then((res) => {
      if (res.data.status === 1) {
        toast.success("Ledger Generated", {
          autoClose: 3000,
          position: "top-center",
        });
        resetForm();
        getLedgerData();
        if (formData.payment_mode === "Cash") {
          getCashData();
        } else {
          getBankData();
        }
      } else {
        resetForm();
      }
    });
  };

  const deleteLedgerData = (res) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(API_URL.LEDGER_DELETE, { id: res.id }).then((res) => {
          if (res.data.status === 1) {
            getLedgerData();
          } else {
            toast.error(res.data.data, {
              autoClose: 3000,
              position: "top-center",
            });
          }
        });
      }
    });
  };

  const resetForm = () => {
    setFormData({
      id: null,
      date: "",
      amount: "",
      remark: "",
      received_by: "",
      payment_mode: "",
      client_id: "",
      ledger_for: "",
      group_id: "",
    });
  };

  const payment = [
    { value: "Cash", label: "Cash" },
    { value: "Bank", label: "Bank" },
  ];

  const getBankData = () => {
    axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Bank" }).then(
      (res) => {
        if (res.data.status === 1) {
          setBankList(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };

  const getCashData = () => {
    axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Cash" }).then(
      (res) => {
        if (res.data.status === 1) {
          setCashList(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getCashData();
    getBankData();
    getClient();
    getGroupClient();
    getLedgerData();
  }, []);

  return (
    <div className="mt-3">
      <div className="accountant-receipt-container">
        <div
          className="accountant-receipt-header"
          style={{ borderBottom: "2px solid black" }}
        >
          <div className="title">Add Receipt</div>
        </div>
        <div>
          <div className="receipt-details">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <strong className="mr-3" style={{ width: "34%" }}>
                Payment Date:
              </strong>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className={`form-control w-34 mt-2 ${
                  isError.date ? "error-input" : ""
                }`}
                placeholderText="Select Date.."
                selected={formData.date}
                onChange={(date) => {
                  setFormData((prevState) => ({ ...prevState, date: date }));
                }}
              />
            </div>
            <div
              className="mt-2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <strong className="mr-3" style={{ width: "25%" }}>
                Ledger For:
              </strong>
              <Select
                className={` w-25  ${isError.ledger_for ? "error-input" : ""}`}
                options={Invoiceoptions}
                value={
                  formData.ledger_for
                    ? Invoiceoptions.find((r) => r.value == formData.label)
                    : null
                }
                onChange={(e) => {
                  handleSelectChange(e);
                  setFormData({ ...formData, ledger_for: e.value });
                }}
              />
            </div>
            {selectedOption.value === "Client" && (
              <div
                className="mt-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <strong style={{ width: "25%" }} className="mr-3">
                  Client Name
                </strong>
                <Select
                  className={` w-25 ${isError.client_id ? "error-input" : ""}`}
                  options={clientList}
                  value={
                    formData.client_id
                      ? clientList.find((r) => r.value == formData.client_id)
                      : null
                  }
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      client_id: e.value,
                      remark: e.label,
                    });
                  }}
                />
              </div>
            )}
            {selectedOption.value === "Group" && (
              <div
                className="mt-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <strong style={{ width: "25%" }} className="mr-3">
                  Group Name
                </strong>
                <Select
                  className={` w-25  ${isError.group_id ? "error-input" : ""}`}
                  options={groupList}
                  value={
                    formData.group_id
                      ? groupList.find((r) => r.value == formData.group_id)
                      : null
                  }
                  onChange={(e) =>
                    setFormData({ ...formData, group_id: e.value })
                  }
                />
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <strong style={{ width: "25%" }} className="mr-3">
                Select Payment Method
              </strong>
              <Select
                className={` w-25 mt-2  ${
                  isError.payment_mode ? "error-input" : ""
                }`}
                options={payment}
                value={
                  formData.payment_mode
                    ? payment.find((r) => r.value == formData.payment_mode)
                    : null
                }
                onChange={(e) =>
                  setFormData({ ...formData, payment_mode: e.value })
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <strong style={{ width: "25%" }} className="mr-3">
                Amount Paid
              </strong>
              <input
                type="number    "
                className={`form-control w-25 mt-2 ${
                  isError.amount ? "error-input" : ""
                }`}
                name="credit_days"
                value={formData.amount}
                onChange={(e) => {
                  setFormData({ ...formData, amount: e.target.value });
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <strong style={{ width: "25%" }} className="mr-3">
                Description
              </strong>
              <input
                type="text"
                className={`form-control w-25 mt-2 ${
                  isError.remark ? "error-input" : ""
                }`}
                name="description"
                value={formData.remark}
                onChange={(e) => {
                  setFormData({ ...formData, remark: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <strong style={{ width: "25%" }} className="mr-3">
            Received by:
          </strong>
          <input
            type="text"
            className={`form-control w-25 mt-2 ${
              isError.received_by ? "error-input" : ""
            }`}
            name="credit_days"
            value={formData.received_by}
            onChange={(e) => {
              setFormData({ ...formData, received_by: e.target.value });
            }}
          />
        </div>
      </div>
      <div className="mt-2">
        <button
          className="btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding"
          style={{ float: "right" }}
          onClick={() => saveLedger()}
        >
          Submit
        </button>
        <button
          className="mr-2 btn btn-danger text-white ctm-border-radius float-right"
          style={{ float: "right" }}
          onClick={() => resetForm()}
        >
          Reset
        </button>
      </div>
      {loading ? (
        <Oval
          height={60}
          width={60}
          color="#7539ff"
          wrapperStyle={{ justifyContent: "center" }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#7539ff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <div className="table-responsive">
          <table className="table custom-table table-bordered mt-4">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Payment Date</th>
                <th style={{ width: "10%" }}>Payment Mode</th>
                <th style={{ width: "35%" }}>Ledger For</th>
                <th style={{ width: "15%" }}>Description</th>
                <th style={{ width: "10%" }}>Remark</th>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "10%" }} className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {ledgers.map((res, key) => {
                return (
                  <tr key={key}>
                    <td>{res.date}</td>
                    <td>{res.payment_mode}</td>
                    <td>{res?.client?.firm_name || res?.group?.name}</td>
                    <td>{res.remark}</td>
                    <td>{res.received_by}</td>
                    <td>{res.amount}</td>
                    <td>
                      <div className="action-section">
                        {/* <button type="button" className="btn btn-sm btn-green btn-action-table"><BiEdit size={25} /></button> */}
                        <button
                          type="button"
                          className="btn btn-sm btn-red btn-action-table"
                          onClick={() => deleteLedgerData(res)}
                        >
                          <AiOutlineDelete size={25} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AddLedger;
