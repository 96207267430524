import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";
import { API_URL } from "../../../const";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { AiOutlineDelete } from "react-icons/ai";
import DatePicker from "react-datepicker";
import { FaSortNumericUp, FaSortNumericDownAlt } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const BankAccount = () => {
  const [bankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [copyBankList, setCopyBankList] = useState([]);
  const [sortByDir, setSortByDir] = useState("DESC");
  const [isShow, setIsShow] = useState(false);
  const [isAdd, setAdd] = useState(false);

  const [bankData, setBankData] = useState({
    id: "",
    description: "",
    remark: "",
  });
  const year = [
    { value: "2022-2023", label: "2022-2023" },
    { value: "2023-2024", label: "2023-2024" },
  ];

  const getBankData = () => {
    axios.post(API_URL.CASH_BANK_LIST, { payment_type: "Bank" }).then(
      (res) => {
        if (res.data.status === 1) {
          setBankList(res.data.data);
          setCopyBankList(res.data.data);
        }
        setLoading(false);
      },
      (error) => {
        toast.error("Something went wrong..", {
          autoClose: 3000,
          position: "top-center",
        });
        setLoading(false);
      }
    );
  };
  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    if (month) {
      const filteredData = copyBankList.filter((elem) => {
        return (
          moment(elem.date).format("MM/YY") === moment(month).format("MM/YY")
        );
      });
      setBankList(filteredData);
    } else {
      setBankList(copyBankList);
    }
  };

  const editbankaccount = (res) => {
    setIsShow(!isShow);
    setBankData({
      id: res.id,
      description: res.description,
      remark: res.remark,
    });
  };

  const deleteData = (res) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085D6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(API_URL.LEDGER_DELETE, { id: res.id }).then((res) => {
          if (res.data.status === 1) {
            getBankData();
          } else {
            toast.error(res.data.data, {
              autoClose: 3000,
              position: "top-center",
            });
          }
        });
      }
    });
  };
  useEffect(() => {
    let copyData = [...bankList];
    if (sortByDir === "DESC") {
      copyData.sort((a, b) => {
        return b.amount - a.amount;
      });
      setBankList(copyData);
    }
  }, [sortByDir]);

  useEffect(() => {
    getBankData();
  }, []);

  const updateBankaccount = () => {
    if (bankData.id && bankData.description && bankData.remark) {
      let cpbankData = { ...bankData };
      axios.post(API_URL.PAYMENT_UPDATE, cpbankData).then((res) => {
        if (res.data.status === 1) {
          toast.success("bankaccount Update", {
            autoClose: 3000,
            position: "top-center",
          });
          setAdd(false);
          setBankData({
            id: "",
            description: "",
            remark: "",
          });
          getBankData();
        }
        setIsShow(false);
      });
    } else {
      toast.error("Please enter valid details", {
        autoClose: 3000,
        position: "top-center",
      });
    }
  };
  return (
    <div className="mt-3">
      {/* <div className='mb-2 w-50'>
                <label className='form-label'>Select Period</label>
                <Select
                    options={year}
                // value={selectedOption}
                // onChange={handleSelectChange}
                />
            </div> */}
      <div className="col-3 center-icon ">
        <div className="selected-date">
          <div className="row align-items-center">
            <div style={{ width: "170px", textAlign: "left" }}>
              <DatePicker
                showIcon
                className="form-control"
                placeholderText="Select Month.."
                selected={selectedMonth}
                onChange={(date) => handleMonthChange(date)}
                showMonthYearPicker
                dateFormat="MM/yyyy"
                isClearable={true}
              />
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <Oval
          height={60}
          width={60}
          color="#7539ff"
          wrapperStyle={{ justifyContent: "center" }}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#7539ff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <div className="table-responsive">
          <table className="table custom-table table-bordered mt-4">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Payment Date</th>
                <th style={{ width: "10%" }}>Payment Mode</th>
                <th style={{ width: "40%" }}>Description</th>
                <th style={{ width: "20%" }}>Remark</th>
                <th style={{ width: "10%" }}>Credit</th>
                <th style={{ width: "10%" }}>Debit</th>
                <th
                  className="text-right"
                  style={{ width: "200px" }}
                  onClick={() => {
                    if (sortByDir === "") {
                      setSortByDir("DESC");
                    }
                  }}
                >
                  <div className="d-flex align-items-center justify-content-end">
                    <div>Amount</div>
                    {<FaSortNumericDownAlt className="ml-1" />}
                  </div>
                </th>
                <th className="text-center" style={{ width: "10%" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {bankList.map((res, key) => {
                return (
                  <tr key={key}>
                    <td>{moment(res.date).format("DD/MM/YYYY")}</td>
                    <td>{res.ledger_type}</td>
                    <td>{res.description}</td>
                    <td>{res.remark}</td>
                    <td>{res.amount_for == "Cr" ? res.amount : ""}</td>
                    <td>{res.amount_for == "Dr" ? res.amount : ""}</td>
                    <td>
                      {res.balance} {res.balance_mode}
                    </td>
                    <td>
                      <div className="action-section">
                        <button
                          type="button"
                          className="btn btn-sm btn-red btn-action-table"
                          onClick={() => {
                            editbankaccount(res);
                          }}
                        >
                          <BiEdit size={25} />
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-red btn-action-table"
                          onClick={() => {
                            deleteData(res);
                          }}
                        >
                          <AiOutlineDelete size={25} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        backdrop="static"
        dialogClassName="modal-70w"
        show={isShow}
        centered
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit BankAccount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <Oval
              height={60}
              width={60}
              color="#7539ff"
              wrapperStyle={{ justifyContent: "center" }}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#7539ff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <div className="page-wrapper">
              <div className="container-fluid">
                <div className="row">
                  <Form noValidate>
                    <div className="col-xl-12 col-lg-8 col-md-12 mb-4">
                      <div
                        className="accordion add-employee"
                        id="accordion-details"
                      >
                        <div className="needs-validation form-row mt-5">
                          <div className="row">
                            <div className="col-6 form-group ">
                              <label className="form-label">Description</label>
                              <input
                                type="text"
                                className="form-control"
                                name="description"
                                value={bankData.description}
                                onChange={(e) => {
                                  setBankData({
                                    ...bankData,
                                    description: e.target.value,
                                  });
                                }}
                                required
                              />
                            </div>
                            <div className="col-6 form-group edit-name">
                              <label className="form-label">Remark</label>
                              <input
                                type="text"
                                className="form-control"
                                name="remark"
                                value={bankData.remark}
                                onChange={(e) => {
                                  setBankData({
                                    ...bankData,
                                    remark: e.target.value,
                                  });
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <button
                        type="button"
                        className="btn btn-theme ctm-border-radius text-white float-right"
                        aria-hidden="true"
                        onClick={updateBankaccount}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          setIsShow(false);
                        }}
                        className="btn btn-danger text-white ctm-border-radius float-right mr-3"
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      
    </div>
  );
};

export default BankAccount;
