import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select'
import NoteModal from "../../component/modal/note-modal";
import Multiselect from 'multiselect-react-dropdown';
import Modal from 'react-bootstrap/Modal';
import TagFilter from "../../component/filter/filterByTag";
import { AiOutlineTag } from 'react-icons/ai';
import { API_URL } from '../../const';
import { Oval } from "react-loader-spinner";
import { toast } from 'react-toastify';
import { GrNotes } from 'react-icons/gr';
import { RxCross2 } from 'react-icons/rx';
import { CiViewColumn } from 'react-icons/ci';
import { FiFilter } from 'react-icons/fi';
import { Form } from 'react-bootstrap';

const LLP = () => {
    const [isShow, setIsShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [itClient, setItClient] = useState([]);
    const [allClientList, setAllClientList] = useState([]);
    const [isFilter, setFilter] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [fiYear, setFiYear] = useState(null);
    const [shownotes, setShowNotes] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [showColum, setShowColum] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([
        { label: "File No", value: "file_no", search: "" },
        { label: "Trade Name", value: "firm_name", search: "" },
        { label: "Mobile No", value: "person_one_no", search: "" },
        { label: "Email", value: "email_one", search: "" },
    ]);
    const [columns, setColumns] = useState([
        { label: "Client Status", value: "is_active", search: "" },
        { label: "File No", value: "file_no", search: "" },
        { label: "Client Name", value: "name", search: "" },
        { label: "Firm Type", value: "firm_type", search: "" },
        { label: "Firm Name", value: "firm_name", search: "" },
        { label: "Address Line 1", value: "address_line_one", search: "" },
        { label: "Address Line 2", value: "address_line_two", search: "" },
        { label: "City", value: "city", search: "" },
        { label: "State", value: "state", search: "" },
        { label: "Pincode", value: "pincode", search: "" },
        { label: "Group Name", value: "group_name", search: "" },
        { label: "Sub Group Name", value: "sub_group_name", search: "" },
        { label: "Joining Date", value: "joining_date", search: "" },
        { label: "Contact Person-1 Name", value: "person_one_name", search: "" },
        { label: "Contact Person-1 Mobile No", value: "person_one_no", search: "" },
        { label: "Contact Person-1 Email", value: "email_one", search: "" },
        { label: "Contact Person-2 Name", value: "person_two_name", search: "" },
        { label: "Contact Person-2 Mobile No", value: "person_two_no", search: "" },
        { label: "Contact Person-2 Email", value: "email_two", search: "" },
        { label: "Mehtaji Name", value: "mehtaji_name", search: "" },
        { label: "Mehtaji No", value: "mehtaji_no", search: "" },
        { label: "PAN No", value: "pan_no", search: "" },
        { label: "PAN Password", value: "pan_password", search: "" },
        { label: "TAN No", value: "tan_no", search: "" },
        { label: "Trace Username", value: "trace_username", search: "" },
        { label: "Trace Password", value: "trace_password", search: "" },
        { label: "Income Tax Password", value: "income_tax_password", search: "" },
        { label: "GSTIN No", value: "gstin_no", search: "" },
        { label: "GSTIN Username", value: "gstin_username", search: "" },
        { label: "GSTIN Password", value: "gstin_password", search: "" },
        { label: "E-waybill Username", value: "ewaybill_username", search: "" },
        { label: "E-waybill Password", value: "ewaybill_password", search: "" },
        { label: "IEC No", value: "iec_no", search: "" },
        { label: "IEC Username", value: "iec_username", search: "" },
        { label: "IEC Password", value: "iec_password", search: "" },
        { label: "MSME No", value: "msme_no", search: "" },
    ]);

    useEffect(() => {
        let today = new Date();
        let year;
        if (today.getMonth() < 3) {
            year = (today.getFullYear() - 1) + "-" + today.getFullYear()
        }
        else {
            year = (today.getFullYear()) + "-" + (today.getFullYear() + 1)
        }
        setFiYear(year);
        getItClient(year);
    }, []);

    // Fetch Data
    const getItClient = (year) => {
        axios.post(API_URL.CLIENT_LLP_AUDIT, { year: year }).then(res => {
            if (res.data.status === 1) {
                res.data.data.map((row) => {
                    if (row.status) {
                        row.status = JSON.parse(row.status);
                    }
                });
                setAllClientList(res.data.data);
                setItClient(res.data.data);
            } else {
                toast.error(res.data.data, "", {
                    autoClose: 3000,
                    position: "top-center",
                });
            }
            setLoading(false);
        });
    }

    const handleItReturn = (e, key, res) => {
        let payload = {
            lookup_id: 4,
            client_id: res.id,
            year: fiYear,
            status: e
        }
        let copyClient = [...itClient];
        copyClient[key].status = payload.status;
        setItClient(copyClient);
        updateData(payload, key);
    }

    const hideShowColumns = (e) => {
        setSelectedColumns(e)
    }

    const renderCheckBox = (row, key, filtertype) => {
        return <Form key={filtertype + '-' + key}>
            {['checkbox'].map((type) => (
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: '15px' }} key={`${filtertype}-${row.id}`}>
                    <Form.Check
                        style={{ display: 'flex', alignItems: 'center' }}
                        type={type}
                        checked={row.checked}
                        id={`${filtertype}-${row.id}`}
                        label={row.name}
                        onChange={(e) => {
                            let items = [];
                            if (filtertype === 'Status') {
                                items = [...isStatus];
                                items[key].checked = e.target.checked;
                                setIsStatus(items);
                            }
                        }}
                    />
                </div>
            ))}
        </Form>
    };

    const updateData = (payload, key) => {
        axios.post(API_URL.CLIENT_LLP_AUDIT_STATUS_UPDATE, payload).then(res => {
            if (res.data.status !== 1) {
                toast.error(res.data.data, "", {
                    autoClose: 3000,
                    position: "top-center",
                });
            }
        });
    }

    const handleShowNotes = (note) => {
        setCurrentRow(note);
        setShowNotes(true);
    }

    const [isStatus, setIsStatus] = useState([
        { id: "Back Up Received", name: "Back Up Received/Ac complete", checked: false },
        { id: "Blank", name: "Noc", checked: false },
        { id: "Received", name: "Opening Balance", checked: false },
        { id: "Suspense", name: "Ledger Scrutiny", checked: false },
        { id: "Done", name: "Fill In Software", checked: false },
        { id: "Filled", name: "Upload", checked: false },
        { id: "Okay", name: "Print", checked: false },
        { id: "eVerified", name: "Sign", checked: false },
        { id: "eVerified", name: "Scan", checked: false },
    ]);

    const optionsStatus = () => {
        return [
            { value: "Back Up Received", label: "Back Up Received/Ac complete" },
            { value: "Blank", label: "Noc" },
            { value: "Received", label: "Opening Balance" },
            { value: "Suspense", label: "Ledger Scrutiny" },
            { value: "Done", label: "Fill In Software" },
            { value: "Filled", label: "Upload" },
            { value: "Okay", label: "Print" },
            { value: "eVerified", label: "Sign" },
            { value: "eVerified", label: "Scan" },
        ]
    }

    const isStatusFilter = (obj) => {
        let selChecked = isStatus.filter((d) => d.checked);
        let status = selChecked.map((res) => { return res.id });
        if (obj.status) {
            for (let i = 0; i < obj.status.length; i++) {
                if (status.includes(obj.status[i].value)) {
                    return true;
                }
            }
        }
        return false;
    };

    const tagFilter = (obj) => {
        let flag = false;
        selectedTags.map((res) => {
            let sel = obj.client_tags.find((d) => d.id === res.value);
            if (sel) {
                flag = true;
            }
        });
        return flag;
    };

    useEffect(() => {
        let copyData = [...allClientList];
        let selSearch = selectedColumns.filter((d) => d.search);
        let selChecked = isStatus.filter((d) => d.checked);
        let status = selChecked.map((res) => { return res.id });
        if (selSearch.length > 0) {
            let filterArray = [];
            copyData.map((res) => {
                let flagArray = [];
                selSearch.map((filter) => {
                    if (res[filter.value]) {
                        let flag = res[filter.value].toLowerCase().indexOf(filter.search.toLowerCase()) > -1 ? true : false;
                        if (flag) {
                            if (status.length > 0) {
                                if (isStatusFilter(res)) {
                                    flagArray.push(flag);
                                }
                            } else {
                                flagArray.push(flag);
                            }
                        }
                    }
                });
                if (flagArray.length === selSearch.length) {
                    filterArray.push(res);
                }
            });
            setItClient(filterArray);
        } else {
            let sel = [];
            copyData.map((res) => {
                if (isStatusFilter(res) || tagFilter(res)) {
                    sel.push(res);
                }
            });
            if (sel.length === 0 && status.length === 0) {
                setItClient(allClientList);
            } else {
                setItClient(sel);
            }
        }
        setFilter(false);
    }, [selectedColumns, isFilter, selectedTags]);
    return (
        <>
            <div className="w-100">
                <div className="ctm-border-radius shadow-sm card">
                    <div className="card-body">
                        <h4 className="title card-title float-left">{itClient.length} Records</h4>
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            {
                                showColum && <div className="row mb-3">
                                    <div className="col">
                                        <Select
                                            isClearable={false}
                                            className="basic-multi-select"
                                            placeholder="Select columns which you want to display"
                                            isMulti={true}
                                            name="columns"
                                            options={columns}
                                            value={selectedColumns}
                                            onChange={(e) => hideShowColumns(e)}
                                        />
                                    </div>
                                </div>
                            }
                            <Modal
                                show={modalShow}
                                size="md"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header className='modal-header-badges'>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='title'>Filter By Status</div>
                                    </div>
                                    <RxCross2 size={25} onClick={() => { setModalShow(false) }} style={{ cursor: 'pointer' }} />
                                </Modal.Header>
                                <Modal.Body className='filter-modal'>
                                    {
                                        isStatus.map((row, key) => {
                                            return <div key={key}>{renderCheckBox(row, key, 'Status')}</div>
                                        })
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className='btn btn-danger text-white ctm-border-radius float-right mr-2' onClick={() => {
                                        let copyData = [...isStatus];
                                        copyData.map((res) => {
                                            res.checked = false;
                                        });
                                        setIsStatus(copyData);
                                        setFilter(true);
                                        setModalShow(false);
                                    }}>Clear</button>
                                    <button className='btn btn-theme button-1 text-white ctm-border-radius p-2 add-person ctm-btn-padding' onClick={() => {
                                        setFilter(true);
                                        setModalShow(false);
                                    }}>Apply</button>
                                </Modal.Footer>
                            </Modal>
                            <div className="employee-office-table">
                                {loading ?
                                    <Oval
                                        height={60}
                                        width={60}
                                        color="#7539ff"
                                        wrapperStyle={{ justifyContent: "center" }}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="oval-loading"
                                        secondaryColor="#7539ff"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    />
                                    :
                                    <div>
                                        <table className="table custom-table table-bordered">
                                            <thead>
                                                <tr>
                                                    {
                                                        selectedColumns.map((row, kd) => {
                                                            return <th key={kd}>{row.label}</th>
                                                        })
                                                    }
                                                    <th>LLP Audit Status</th>
                                                    <th>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div>Action</div>
                                                            <div> <CiViewColumn style={{ textAlign: 'end' }} color='white' cursor={'pointer'} size={22} onClick={(() => setShowColum(!showColum))} /></div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {selectedColumns.map((row, kd) => {
                                                        return (
                                                            <td style={{ width: '12%' }} key={kd}>
                                                                <input
                                                                    placeholder='Search...'
                                                                    type="text"
                                                                    style={{ width: '100%', minWidth: '100px', padding: '0px 8px' }}
                                                                    className="form-control form-control-sm"
                                                                    name="name"
                                                                    value={row.search}
                                                                    onChange={(e) => {
                                                                        let copyData = [...selectedColumns];
                                                                        copyData[kd].search = e.target.value;
                                                                        setSelectedColumns(copyData);
                                                                    }}
                                                                />
                                                            </td>
                                                        )
                                                    })}
                                                    <td className="text-center"><FiFilter color={isStatus.filter((d) => d.checked).length > 0 ? "blue" : "black"} cursor={'pointer'} size={22} onClick={() => setModalShow(true)} /></td>
                                                    <td className='text-center'>
                                                        <button type="button" className="btn btn-sm btn-red btn-action-table" onClick={() => { setIsShow(!isShow) }} >
                                                            <AiOutlineTag size={25} />
                                                        </button>
                                                    </td>
                                                </tr>
                                                {itClient.map((res, key) => {
                                                    return (
                                                        <tr key={key} >
                                                            {
                                                                selectedColumns.map((row, lk) => {
                                                                    return <td key={key + '' + lk}>
                                                                        {row.value === 'firm_name' ? <>
                                                                            {res[row.value]}
                                                                            <div>
                                                                                {res.client_tags.map((el, tk) => {
                                                                                    return (<span key={tk} style={{ backgroundColor: '#511989' }} className='badge badge-primary mr-1'>{el.name}</span>)
                                                                                })}
                                                                            </div>
                                                                        </> : <>{res[row.value]}</>}
                                                                    </td>
                                                                })
                                                            }
                                                            <td>
                                                                <Multiselect
                                                                    displayValue="label"
                                                                    selectedValues={res.status}
                                                                    onSelect={(e) => handleItReturn(e, key, res)}
                                                                    onRemove={(e) => handleItReturn(e, key, res)}
                                                                    options={optionsStatus()}
                                                                    showCheckbox
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className="action-section">
                                                                    <button type="button" className="btn btn-sm btn-red btn-action-table" onClick={
                                                                        () => { handleShowNotes(res) }} ><GrNotes size={22} /></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                <TagFilter open={isShow} onClose={(tags) => {
                                    setIsShow(false);
                                    if (tags) {
                                        setSelectedTags(tags);
                                    }
                                }} ></TagFilter>
                                <NoteModal firmType={'LlpAudit'} openNotes={shownotes} note={currentRow} onCloseNotes={() => setShowNotes(false)}></NoteModal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LLP
